@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


@font-face {
  font-family: 'Lemon Milk';
  src: url('./assets/fonts/lemon-milk-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body, html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background-color: #eaeaea;
  font-family: 'Lemon Milk', sans-serif;
}

.App {
  text-align: center;
}