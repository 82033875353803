/* MyCarousel.css */

/* Remove qualquer animação de opacidade nos dots */
.carousel .control-dots .dot {
  transition: none; /* Remove transições */
}

.carousel .control-dots .dot:hover {
  opacity: 1; /* Certifique-se de que a opacidade seja 1 ao passar o mouse */
}


/* Ajusta a altura do carrossel para telas grandes */
.carousel .slide {
  height: 680px; /* Altura fixa para telas grandes */
  background-size: cover; /* Faz a imagem de fundo cobrir todo o slide */
  background-position: center; /* Centraliza a imagem de fundo */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; /* Cor de fundo para o slide */
}


.carousel .slide div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.carousel .slide h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.carousel .slide p {
  font-size: 1.5rem;
}

/* Estilo para os botões */
.carousel .slide button {
  padding: 10px 20px;
  font-size: 1rem;
  margin-top: 1rem;
  background-color: #FFD700; /* Cor amarela ouro */
  border: none; /* Remove a borda padrão */
  border-radius: 10px; /* Bordas arredondadas */
  cursor: pointer; /* Muda o cursor para pointer ao passar o mouse */
  transition: background-color 0.3s ease; /* Transição suave para o hover */
}

/* Efeito de hover para o botão */
.carousel .slide button:hover {
  background-color: #DAA520; /* Cor dourada mais escura ao passar o mouse */
}

/* Estilo para as imagens nos slides */
.carousel .slide img {
  max-width: 400px; /* Garante que a imagem não ultrapasse a largura do contêiner */
  height: auto; /* Mantém a proporção da imagem */
}

/* Ajuste para dispositivos móveis */
@media (max-width: 768px) {
  .carousel .slide {
    height: 500px; /* Altura fixa para telas menores (celulares) */
  }

  .carousel .slide h1 {
    font-size: 2rem; /* Ajusta o tamanho da fonte para telas menores */
  }

  .carousel .slide p {
    font-size: 1rem; /* Ajusta o tamanho do texto */
    padding: 20px; /* Adiciona padding ao texto */
  }

  .carousel .slide button {
    font-size: 0.9rem; /* Ajusta o tamanho do botão */
  }

  .carousel .slide img {
    max-width: 250px; /* Reduz a largura máxima da imagem para 80% em telas menores */
  }
}