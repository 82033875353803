.contact-container {
    display: flex; /* Layout flexível para a divisão esquerda e direita */
    height: 350px; /* Altura total da tela */
    background-image: url('../../assets/img/png/contact_background.png'); /* Imagem de fundo */
    background-size: cover; /* Cobre todo o fundo */
    background-position: center; /* Centraliza a imagem de fundo */
    padding-top: 40px;
    padding-bottom: 40px;
}

.form-section {
    flex: 1; /* A seção do formulário ocupa 40% do espaço */
    display: flex;
    flex-direction: column; /* Alinha os itens verticalmente */
    justify-content: center; /* Centraliza verticalmente */
    background-color: rgba(255, 255, 255, 0.3); /* Fundo branco semi-transparente */
    border-radius: 8px; /* Bordas arredondadas */
    margin-left: 25%; /* Margem em torno da seção do formulário */
    max-width: 50%; /* Largura máxima de 70% */
    width: 100%; /* Largura total para mobile */
}

.contact-form {
    display: flex;
    flex-direction: column; /* Alinha os campos verticalmente */
    width: 100%; /* Garante que o formulário ocupe toda a largura disponível */
}

.contact-form h2 {
    text-align: center; /* Centraliza o título */
    margin-bottom: 20px;
    color: #333; /* Cor do texto do título */
}

.form-group {
    padding-left: 30px;
    margin-bottom: 15px; /* Espaçamento entre os grupos de formulário */
    max-width: 90%;
}

.form-group label {
    display: block; /* Faz o label ocupar toda a largura */
    margin-bottom: 5px;
    color: #555; /* Cor do texto dos labels */
}

.form-group-button {
    width: 300px;
    margin: auto;
}

input, 
textarea, 
button.submit-button {
    width: 100%; /* Largura total para os campos e botão */
    padding: 10px; /* Preenchimento interno */
    border: 1px solid #ccc; /* Borda padrão */
    border-radius: 8px; /* Bordas arredondadas */
    font-size: 16px; /* Tamanho da fonte */
}

button.submit-button {
    background-color: #323e56; /* Cor de fundo do botão */
    color: white; /* Cor do texto */
    border: none; /* Remove a borda padrão */
    cursor: pointer; /* Cursor de ponteiro */
    transition: background-color 0.3s; /* Transição suave na cor de fundo */
}

button.submit-button:hover {
    background-color: #2d2e3b; /* Cor do botão ao passar o mouse */
}

.image-section {
    flex: 0 0 60%; /* A seção da imagem ocupa 60% do espaço */
    display: flex; /* Flexível para centralizar a imagem */
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
}

.contact-image {
    max-width: 100%; /* A imagem se ajusta ao tamanho do contêiner */
    height: auto; /* Mantém a proporção da imagem */
}

/* ContactForm.css */
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db; /* Cor do spinner */
    border-radius: 50%;
    width: 24px; /* Tamanho do spinner */
    height: 24px; /* Tamanho do spinner */
    animation: spin 0.8s linear infinite;
    margin: 0 auto; /* Centraliza o spinner */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .contact-container {
        flex-direction: column; /* Altera para coluna em telas pequenas */
        height: auto; /* Ajusta a altura para auto */
        padding-top: 20px; /* Ajusta o padding para cima */
        padding-bottom: 20px; /* Ajusta o padding para baixo */
    }

    .form-section {
        margin-left: 5%; /* Remove a margem esquerda */
        margin-top: 20px; /* Adiciona margem superior */
        max-width: 90%; /* Largura máxima para telas pequenas */
        width: 100%; /* Largura total em telas pequenas */
    }

    .form-group {
        margin-left: 10px;
    }

    .image-section {
        flex: 0 0 100%; /* A seção da imagem ocupa 100% do espaço */
        margin-top: 20px; /* Adiciona margem superior */
    }
    
    .form-group {
        padding-left: 0; /* Remove o padding à esquerda para melhor ajuste */
    }
}
