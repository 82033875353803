.chat-container {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 600px;
  width: 400px;
  position: fixed;
  bottom: 100px;
  right: 30px;
  border-color: #000;
  border-width: 2dvi;
  padding: 1px;
  background-color: #bababa;
  z-index: 100;
}

.chat-header {
  background-color: #4b4b4b;
  text-align: center;
  color: #fff;
  height: 40px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.chat-header-text {
  margin-top: 11px;
  font-size: 16px;
}

.chat-body {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
}

.message-list {
  display: flex;
  flex-direction: column;
}

.message {
  display: flex;
  align-items: flex-start; /* Alinhar avatares e mensagens no topo */
  margin-bottom: 10px;
  font-size: 16px;
}

.message.bot {
  justify-content: flex-start;
}

.message.user {
  justify-content: flex-end;
}

.avatar {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.message-content {
  max-width: 80%;
  padding: 10px;
  border-radius: 10px;
  background-color: #f9f9f9;
  position: relative;
}

.message.user .message-content {
  background-color: rgb(227, 227, 227);
  color: #000;
}

.message-timestamp {
  font-size: 0.75em;
  color: #888;
  margin-top: 10px;
}

.message.user .message-timestamp {
  color: #2b0386; /* Cor do horário da mensagem do usuário */
}

.chat-footer {
  display: flex;
  border-top: 1px solid #ddd;
  padding: 10px;
}

.chat-footer input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.chat-footer button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #4b4b4b;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  border-radius: 50%;
  background-color: #4b4b4b;
  animation: blink 1s infinite both;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%,
  20% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 0.2;
  }
}


@media (max-width: 600px) {
  .chat-container {
    height: 80%; /* Altura reduzida para celulares */
    width: 90%;  /* Largura reduzida para celulares */
    bottom: 20px; /* Mover um pouco mais para cima */
    right: 5%;   /* Ajustar a posição para o centro */
    z-index: 2000;
  }

  .chat-header {
    height: 50px; /* Aumentar altura do cabeçalho */
  }

  .chat-header-text {
    font-size: 18px; /* Aumentar o tamanho da fonte do cabeçalho */
  }

  .message {
    font-size: 16px; /* Aumentar o tamanho da fonte da mensagem */
  }

  .avatar {
    width: 30px; /* Aumentar tamanho do avatar */
    height: 30px; /* Aumentar tamanho do avatar */
  }

  .chat-footer input {
    padding: 12px; /* Aumentar padding do campo de entrada */
  }

  .chat-footer button {
    padding: 12px 24px; /* Aumentar padding do botão */
  }
}