/* Navbar container */
.navbar {
    background-color: #323e56;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    font-family: 'Lemon Milk', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adiciona sombra para efeito de elevação */
    transition: box-shadow 0.3s ease; /* Transição suave na sombra */
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}

.navbar-logo img {
    height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.navbar-menu {
    list-style-type: none;
    display: flex;
    gap: 30px;
    margin: 0;
}

.navbar-menu li {
    margin: 0;
}

.navbar-menu a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-family: 'Lemon Milk', sans-serif;
    cursor: pointer !important; /* Adicionado para mudar o cursor ao passar o mouse */
}

.navbar-menu li a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-family: 'Lemon Milk', sans-serif;
    cursor: pointer; /* Mantém o cursor como ponteiro */
}

.navbar-menu a:hover {
    color: #f4a261;
}

/* Hamburger menu for mobile */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
    margin-right: 40px;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    transition: 0.3s;
}

/* Open menu when clicked */
.navbar-menu.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0; /* Alinhado à direita da tela */
    background-color: #2a3a47;
    padding: 20px;
    border-radius: 5px;
    border-right: 20px solid #2a3a47; /* Borda direita de 20px */
    width: 250px; /* Ajuste a largura conforme necessário */
    box-sizing: border-box; /* Inclui padding e borda na largura total */
}

.navbar-button-member {
    display: none;
}

.navbar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    position: relative; /* Permite que os elementos absolutos sejam posicionados corretamente */
}

.submenu-container {
    position: relative; /* Define o ponto de referência para o submenu */
}

.submenu {
    position: absolute;
    top: 100%; /* Alinha o submenu logo abaixo do item do menu */
    left: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 10px 0;
    margin: 0;
    display: none; /* Escondido por padrão */
    z-index: 1000; /* Garante que ele fique acima de outros elementos */
    border-radius: 10px;
}

.submenu-container:hover .submenu {
    display: block; /* Exibe o submenu ao passar o mouse */
}

.submenu li {
    padding: 10px 20px;
}

.submenu li a {
    text-decoration: none;
    color: #323e56;
    cursor: pointer;
}

.submenu li a:hover {
    color: #3d84b8;
}
  
@media (max-width: 768px) {
    .navbar-menu {
        display: none;
    }
    
    .navbar-menu.open {
        display: flex;
    }

    .hamburger {
        display: flex;
    }

    .navbar-buttons {
        display: none;
        position: absolute;
        top: 15px; /* Ajusta a altura do botão em relação ao topo */
        right: 80px; /* Ajusta a posição do botão em relação ao hambúrguer */
    }

    .navbar-button-member {
        display: flex;
        position: absolute;
        top: 7px; /* Ajusta a altura do botão em relação ao topo */
        right: 115px; /* Ajusta a posição do botão em relação ao hambúrguer */
        width: 70px;
    }

    .navbar-buttons a {
        padding: 8px 12px; /* Ajusta o tamanho do botão para telas menores */
        font-size: 12px;
    }

    .navbar-logo img {
        height: 24px;
        margin-top: 15px;
        margin-bottom: 10px;
        width: 150px;
    }
}
