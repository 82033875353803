.service-card:hover {
    transform: scale(1.05); /* Efeito de zoom ao passar o mouse */
}

.products-section {
    margin-top: 30px;
    padding: 20px;
    text-align: center;
    background-color: #e8e8e8;
    margin-bottom: 30px;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Espaçamento entre os cards */
    margin-top: 40px;
    margin-bottom: 40px;
}

.service-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    width: 200px; /* Largura do card */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s; /* Efeito ao passar o mouse */
    cursor: pointer; /* Adiciona o cursor pointer */
}

.service-card:hover {
    transform: translateY(-5px); /* Leve elevação ao passar o mouse */
}

.service-icon {
    font-size: 40px; /* Tamanho do ícone */
    margin-bottom: 10px; /* Espaçamento entre ícone e texto */
}

@media (max-width: 768px) {
    .cards-container {
        flex-direction: column; /* Alinha os cards em uma coluna */
        align-items: center; /* Centraliza os cards */
    }

    .service-card {
        width: 90%; /* O card ocupará 90% da largura da tela em dispositivos menores */
    }
}