.base-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .base-modal-content {
    background-color: #2a3a47;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
  }
  
  .base-modal-content img {
    display: block;
    margin: 0 auto 30px;
  }
  
  form {
    text-align: left;
  }
  
  form div {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #fff;
    font-size: 13px;
    margin-top: 20p;
  }
  
  input {
    width: 94%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .button-container {
    text-align: center; /* Alinha os botões à direita */
  }
  
  button {
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Lemon Milk';
  }
  
  button[type="submit"] {
    background-color: #3d84b8;
    color: #fff;
    font-family: 'Lemon Milk';
  }
  
  button[type="button"], button[type="submit"] {
    display: inline-block;
  }
  
  button[type="button"] {
    background-color: #d9534f;
    color: #fff;
  }