/* Squad.css */
.squad-container {
    text-align: center; /* Centraliza o texto */
    padding: 20px;
    font-family: 'Lemon Milk', sans-serif;
}

.squad-members {
    display: flex;
    justify-content: space-around; /* Espaço entre os membros */
    flex-wrap: wrap; /* Permite que os membros se ajustem em várias linhas */
}

.squad-member {
    flex: 1 1 200px; /* Define o tamanho mínimo dos membros */
    max-width: 300px; /* Limita a largura máxima dos membros */
    margin: 10px; /* Margem entre os membros */
    text-align: center; /* Centraliza o texto */
    position: relative; /* Para que a sombra se ajuste ao contêiner */
    overflow: hidden; /* Para ocultar o excesso */
    transition: transform 0.3s ease; /* Suaviza a transformação */
}

.squad-member:hover {
    transform: scale(1.05); /* Aumenta o tamanho ao passar o mouse */
}

.member-image {
    width: 100%; /* A imagem ocupa 100% da largura do contêiner */
    height: 300px; /* Define uma altura fixa para as imagens */
    object-fit: cover; /* Mantém a proporção e cobre o espaço */
    border-radius: 10px; /* Borda arredondada */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Sombra na imagem */
    transition: box-shadow 0.3s ease; /* Transição suave para a sombra */
}

.member-image:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5); /* Aumenta a sombra no hover */
}

.description {
    margin-top: 10px; /* Espaçamento acima do texto descritivo */
    font-size: 14px; /* Tamanho da fonte para a descrição */
}

/* Media Queries para responsividade */
@media (max-width: 768px) {
    .squad-member {
        flex: 1 1 100%; /* Cada membro ocupa 100% da largura em telas menores */
        max-width: 100%; /* Remove a largura máxima */
    }

    .member-image {
        height: 350px; /* Ajusta a altura das imagens em telas menores */
    }

    .description {
        font-size: 12px; /* Reduz o tamanho da fonte da descrição */
    }
}
