.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 199;
}

.modal-content {
    background: #323e56;
    padding: 20px; /* Reduzir o padding se necessário */
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: auto; /* Permitir que a largura seja ajustada ao conteúdo */
    max-width: 90%; /* Limita a largura máxima */
    max-height: 80%; /* Limita a altura máxima do modal */
    color: #fff;
    overflow-y: auto; /* Permite rolagem vertical */
}

.modal-content h2 {
    margin: 0 0 10px;
}

.modal-content button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #2d2e3b;
    color: white;
    cursor: pointer;
}

.modal-content button:hover {
    background-color: #3d4051;
    color: #fff;
    cursor: pointer;
}
