.adm-report-file-upload-container {
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    font-size: 12px;
  }
  
  h2 {
    text-align: center;
  }
  
  .add-report-button {
    display: block;
    margin: 20px auto;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .adm-report-add-report-button:hover {
    background-color: #1d4e1f;
  }
  
  .adm-report-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .adm-report-modal-content {
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    margin: 5px 0;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .adm-report-file-upload-container {
    padding: 20px;
  }
  
  .adm-report-add-report-button-right {
    float: right;
    margin-bottom: 20px;
  }