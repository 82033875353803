.reports-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px;
}

.reports-logo {
    width: 250px; /* Adjust as necessary */
    margin-bottom: 20px;
    margin-top: 50px;
}


.reports-list {
    display: flex;               /* Use flexbox for horizontal layout */
    flex-wrap: wrap;           /* Allow cards to wrap to the next line if needed */
    justify-content: center;    /* Center the cards */
    gap: 20px;                 /* Space between cards */
}

.report-card {
    background: #fff; /* Card background color */
    border: 1px solid #e1e1e1; /* Border color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 20px;
    width: 300px; /* Card width */
    text-align: center; /* Center the text */
}

.report-image {
    width: 100%; /* Full width of the card */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px; /* Optional: rounded corners for the image */
    margin-bottom: 15px; /* Space below the image */
}

.report-name {
    font-size: 18px; /* Title size */
    margin-bottom: 10px; /* Space below the title */
}

.report-description {
    font-size: 18px; /* Description size */
    margin-bottom: 15px; /* Space below the description */
    color: #555; /* Description color */
    font-family: 'Roboto, sans-serif';
}

.download-button {
    display: inline-block; /* Ensure the button behaves like a block */
    background-color: #3d4051; /* Button color */
    color: #fff; /* Button text color */
    padding: 10px 20px; /* Padding inside the button */
    border-radius: 5px; /* Rounded corners */
    text-decoration: none; /* Remove underline */
    transition: background-color 0.3s; /* Smooth background change */
}

.download-button:hover {
    background-color: #2e6f9f; /* Darker shade on hover */
}

.report-icon {
    width: 60px;
}

.no-reports-message {
    text-align: center;
    color: #555;
    font-size: 16px;
    margin-top: 20px;
}