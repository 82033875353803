/* Footer.css */
.footer {
    background-color: #323e56;
    color: white;
    padding: 20px;
    text-align: justify;
    font-size: 10px;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.social-icons {
    display: flex;
}

.footer-content {
    width: 80%;
    margin-bottom: 40px;
    font-size: 10px;
    margin-left: 10%;
    background-color: #323e56;
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
    text-align: center;
}

.footer a {
    color: white;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

.logo-footer {
    height: 40px;
}

@media (max-width: 768px) {
    .footer {
        font-size: 10px;
    }

    .footer-content p {
        font-size: 10px;
    }

    .logo-footer {
        height: 30px;
    }
}

@media (max-width: 480px) {
    .footer-content {
        padding: 0 2%;
    }
}
