.about-container {
    display: flex; /* Utiliza flexbox para o layout */
    align-items: center; /* Centraliza verticalmente os itens */
    justify-content: space-between; /* Espaço entre texto e logo */
    padding: 80px; /* Espaçamento interno */
    /* background-color: #323e56; /* Cor de fundo */
    background: linear-gradient(135deg, #323e56, #2d2e3b);
    color: #fff; /* Cor do texto */
}

.about-text {
    flex: 1; /* O texto ocupa uma parte do espaço */
    max-width: 50%; /* O texto ocupa no máximo 50% da largura */
    margin-right: 20px; /* Espaço à direita do texto */
}

.about-logo {
    flex: 1; /* O logo ocupa uma parte do espaço */
    display: flex;
    justify-content: center; /* Centraliza o logo horizontalmente */
}

.about-logo img {
    max-width: 40%; /* Garante que o logo não exceda a largura do contêiner */
    height: auto; /* Mantém a proporção do logo */
}

/* Estilo para o link */
.about-text a {
    color: white; /* Define a cor do link como branco */
    text-decoration: none; /* Remove o sublinhado */
    font-weight: bold; /* Torna o texto do link em negrito */
}

/* Media Queries para responsividade */
@media (max-width: 768px) {
    .about-container {
        flex-direction: column; /* Altera para coluna em telas pequenas */
        align-items: center; /* Centraliza os itens verticalmente */
        text-align: center; /* Centraliza o texto */
    }

    .about-text {
        max-width: 100%; /* O texto ocupa 100% da largura */
        margin-right: 0; /* Remove margem à direita */
        margin-bottom: 20px; /* Espaçamento abaixo do texto */
    }

    .about-logo {
        max-width: 100%; /* O logo ocupa 100% da largura */
    }
}