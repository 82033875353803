.container {
  text-align: center; /* Centraliza o conteúdo */
  padding-top: 50px; /* Padding superior */
  padding-bottom: 50px; /* Padding inferior */
  margin-top: 70px;
}

.logoLP {
  max-width: 650px;
  height: auto; /* Mantém a proporção do logo */
  margin-bottom: 30px;
}

.logoLP2 {
  max-width: 450px;
  height: auto; /* Mantém a proporção do logo */
  margin-bottom: 30px;
}


.content {
  max-width: 80%; /* Reduz a largura do conteúdo */
  margin: 0 auto; /* Centraliza o conteúdo */
  background-color: #323e56; /* Fundo translucido */
  border-radius: 10px; /* Bordas arredondadas */
  padding: 80px; /* Padding interno */
  color: #fff; /* Cor do texto */
  font-size: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7); /* Sombra suave */
}

/* Media Query para dispositivos móveis */
@media (max-width: 768px) {

  .logoLP {
      max-width: 70%;
      margin-top: 1cap; /* Garante que o logo não ultrapasse a largura do container */
      height: auto; /* Mantém a proporção do logo */
      margin-bottom: 30px;
  }

  .logoLP2 {
    max-width: 70%;
    margin-top: 2cap; /* Garante que o logo não ultrapasse a largura do container */
    height: auto; /* Mantém a proporção do logo */
    margin-bottom: 30px;
}

  .content {
      margin: 0 auto; /* Centraliza o conteúdo */
      background-color: #323e56; /* Fundo translucido */
      border-radius: 10px; /* Bordas arredondadas */
      padding: 40px; /* Padding interno */
      color: #fff; /* Cor do texto */
      font-size: 16px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7); /* Sombra suave */
  }
}
